import React from 'react';
import { Slide } from 'react-slideshow-image';
import "react-slideshow-image/dist/styles.css"

const Slideshow = () => {
    return (
      <div className='slide-show-container'>
        <Slide easing="ease">
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${require('assets/images/slide-show/1.jpg')})`}}/>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${require('assets/images/slide-show/2.JPG')})`}}/>
          </div>
          <div className="each-slide">
            <div style={{'backgroundImage': `url(${require('assets/images/slide-show/3.JPG')})`}}/>
          </div>
        </Slide>
      </div>
    )
};

export default Slideshow;