import './index.css'

import { FunctionComponent } from "react";


import { Address as AddressType } from 'api/applications/types';

type AddressProps = {
  description: string;
  address: AddressType;
}

const Address: FunctionComponent<AddressProps> = ({
  description,
  address
}) => {
  return ( 
    <div className='address'>
        <div className='description'>{description}</div>
        <div className='details'>
          <div className='line'>{address.line1}</div>
          <div className='line'>{address.line2}</div>
          <div className='line'>{address.line3}</div>
          <div className='line'>{address.postalCode}</div>
        </div>
    </div>
  );
}

export default Address;