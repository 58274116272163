/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react';
import { openInNewTab } from "shared/utils/actions";

const Footer: FunctionComponent = () => {
  return (
    <div id='contact' className='footer'>
        <div className="top">
          <div className="left">
            <div className="logo" />
            <div className="contact-details">
              <div className="text">Western Cape, Capetown, 7800</div>
              <div className="text">Email: info@focusmentorship.com</div>
              <div className="text">Tel: +27 65 843 0798</div>
            </div>
          </div>
          <div className="right">
            <div className="nav-section">
                <div className="heading">SOCIALS</div>
                <div 
                  className="nav-item" 
                  onClick={() => openInNewTab("https://www.facebook.com/FOCUS-Mentorship-186104458799069")}
                >Facebook</div>
                <div 
                  className="nav-item" 
                  onClick={() => openInNewTab("https://www.instagram.com/focusmentorship/")}
                >Instagram</div>
                <div 
                  className="nav-item" 
                  onClick={() => openInNewTab("https://wa.me/27658430798")}
                >WhatsApp</div>
            </div>
            <div className="nav-section">
                <div className="heading">Programme</div>
                <div 
                  className="nav-item" 
                  onClick={() => document.getElementById("about")?.scrollIntoView({ block: 'start' })}
                >About</div>
                <div 
                  className="nav-item" 
                  onClick={() => document.getElementById("team")?.scrollIntoView({ block: 'center' })}
                >Team</div>
                <div 
                  className="nav-item" 
                  onClick={() => document.getElementById("news-and-updates")?.scrollIntoView({ block: 'center' })}
                >News & Updates</div>
            </div>
            <div className="nav-section">
                <div className="heading">App Digital Platforms</div>
                <div 
                  className="nav-item" 
                  onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=com.targetonline.focus")}
                >Google Play Store</div>
                <div 
                  className="nav-item" 
                  onClick={() => openInNewTab("https://apps.apple.com/us/app/focus-mentorship/id1507260739")}
                >Apple App Store</div>
                <div 
                  className="nav-item" 
                  onClick={() => openInNewTab("https://appgallery.huawei.com/app/C104343307")}
                >Huawei App Gallery</div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Footer;
