/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react'
import { openInNewTab } from "shared/utils/actions";

const Team: FunctionComponent = () => {
  return (
    <div id='team' className='team'>
      <div className="title">Team</div>
      <div className="members">
        <div 
          className="member"
          title="LinkedIn Profile"
          onClick={() => openInNewTab('https://www.linkedin.com/in/nkululekomemela/')}
        >
          <div className="pic-container">
              <div 
                className="pic" 
                style={{ backgroundImage: `url(${require("../../../assets/images/home-page/team/nkululeko.png")})` }}
              />
          </div>
          <div className="full-name">Nkululeko Memela</div>
          <div className="role">Data Analyst</div>
        </div>
        <div 
          className="member"
          title="LinkedIn Profile"
          onClick={() => openInNewTab('https://www.linkedin.com/in/skhumbuzo-mkhungo-4a7a9b127/')}
        >
          <div className="pic-container">
              <div 
                className="pic" 
                style={{ backgroundImage: `url(${require("../../../assets/images/home-page/team/skhumbuzo2.jpg")})` }}
              />
          </div>
          <div className="full-name">Skhumbuzo Mkhungo</div>
          <div className="role">Software Developer</div>
        </div>
        <div 
          className="member"
          title="LinkedIn Profile"
          onClick={() => openInNewTab('https://www.linkedin.com/in/bahle-chonco-455515b2/')}
        >
          <div className="pic-container">
              <div 
                className="pic" 
                style={{ backgroundImage: `url(${require("../../../assets/images/home-page/team/bahle.jfif")})` }}
              />
          </div>
          <div className="full-name">Bahle Chonco</div>
          <div className="role">Project Analyst</div>
        </div>
      </div>
    </div>
  );
};

export default Team;
