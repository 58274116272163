import './index.css'

import { FunctionComponent, useContext } from "react";
import HeaderFooter from 'home/sections/header-footer';
import ApplicationCard from './card';
import { Application } from 'api/applications/types';
import CircularProgress from "@material-ui/core/CircularProgress";
import { CurrentUserContext } from 'store';

type ApplicationsProps = {
  applications: Application[];
  inProgress: boolean;
  updateApplicationStatus: (application: Application) => void;
}

const Applications: FunctionComponent<ApplicationsProps> = ({
  applications,
  inProgress,
  updateApplicationStatus
}) => {
  const [currentUser] = useContext(CurrentUserContext);
  return ( 
    <HeaderFooter isHomePage={false}>
      <div className='applications'>
        <div className='background-banner' />
        <div className='main-section' >
          <div className='logo-section'>
            <div className='logo' />
          </div>
          <div className='heading'> Applications </div>
          <div className='cards-board'>
          {inProgress ? <CircularProgress color="inherit" /> : (
            <div className='cards-container'>
              {applications
              .filter((a: Application) => a.applicant.id === currentUser.id || currentUser.isAHSAdmin)
              .map((application: Application, i) => (
                <ApplicationCard 
                  key={i}
                  application={application}
                  updateApplicationStatus={updateApplicationStatus}
                />
              ))}
            {applications.length === 0 && (
              <div className='no-applications'> No Applications </div>
            )}
            </div>
          )}
          </div>
        </div>
      </div>
    </HeaderFooter>
  );
}

export default Applications;