import { User } from "api/authentication/types"

export const shadow = {
    elevation: 2,
    shadowOpacity: 0.15,
    shadowRadius: 4
}

export const userInitialState : User = {
    id: 0,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    password: '',
    avatarURL: '',
    address: '',
    isAdmin: false,
    isTeacher: false,
    isAHSAdmin: false,
    IsPasswordEncrypted: false,
    isPhoneNumberVerified: false,
    lastUpdatedAt: new Date(), 
    expoPushToken: '',
    isTutor: false,
    hasAvatar: false,
    tutorAttributeId: 0
}