import MainBanner from './sections/main-banner';
import HistoryAndLegacy from './sections/about';
import About from './sections/team';
import StatsSection from './sections/stats-section';
import SocialsSection from './sections/socials';
import NewsAndUpdates from './sections/news-and-links';
import Contact from './sections/contact';
import HeaderFooter from './sections/header-footer';

export default function App() {
  return ( 
    <HeaderFooter isHomePage>
      <>
        <MainBanner /> 
        <HistoryAndLegacy />  
        <About />
        <StatsSection />
        <SocialsSection />
        <NewsAndUpdates />
        <Contact />
      </>
    </HeaderFooter>
  );
}