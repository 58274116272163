/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";

type NavBarProps = {
  isVisible: boolean;
  setVisible: (value: boolean) => void;
}

const SideNavBar: FunctionComponent<NavBarProps> = ({
  isVisible,
  setVisible
}) => {
  const navigate = useNavigate();
  const [closeNavBarClicked, setcloseNavBarClicked] =  useState(false)
  return (
    <div
      className={`side-nav-bar  ${
        isVisible ? "side-nav-bar-open" : closeNavBarClicked ? "side-nav-bar-close animation-slide-out-right" : "side-nav-bar-close"
      }`}
    >
    <div className="close-bar" onClick={() => { 
      setVisible(false);
      setcloseNavBarClicked(true);
    }}>
      <FontAwesomeIcon className='animate__animated animate__zoomIn' color='#979797' icon={faClose} size='2x' />
    </div>
      <div className="side-nav-bar-items">
        <div
          className="side-nav-bar-item"
          onClick={() => navigate('/')}
        >
          HOME
        </div>
        <div
          className="side-nav-bar-item"
          onClick={() => navigate('/login')}
        >
          Login
        </div>
      </div>
    </div>
  );
};

export default SideNavBar;
