/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react';
import { openInNewTab } from "shared/utils/actions";

const Footer: FunctionComponent = () => {
  return (
    <div id='footer' className='footer'>
        <div className="bottom">
          <div className="left">©Copyright 2022 Focus Mentorship Pty Ltd. All Rights Reserved. Designed by Focus Mentorship Marketing</div>
          <div className="right">
            <img 
              alt="logo"
              className="facebook-logo" 
              src={require('../../../assets/images/home-page/icons/facebook-logo-white.png')}
              onClick={() => openInNewTab("https://www.facebook.com/FOCUS-Mentorship-186104458799069")} 
            />
            <img 
              alt="logo"
              className="instagram-logo" 
              src={require('../../../assets/images/home-page/icons/instagram-logo-white.png')}
              onClick={() => openInNewTab("https://www.instagram.com/focusmentorship/")} 
            />
            
            <img 
              alt="logo"
              className="whatsapp-logo" 
              src={require('../../../assets/images/home-page/icons/whatsapp-logo-white.png')}
              onClick={() => openInNewTab("https://wa.me/27658430798")} 
            />
          </div>
        </div>
    </div>
  );
};

export default Footer;
