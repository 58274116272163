import './index.css'

import { FunctionComponent } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons'

import { Application } from 'api/applications/types';
import { isStringEmpty } from 'shared/utils/string';
import Address from './Address';
import Signature from './Signature';
import ApplicationStatus from './ApplicationStatus';
import { openInNewTab } from 'shared/utils/actions';

type ApplicationsProps = {
  isVisible: boolean;
  application: Application;
  setVisible: (value: boolean) => void;
  updateApplicationStatus: (application: Application) => void;
}

const ApplicationDetailsModal: FunctionComponent<ApplicationsProps> = ({
  application,
  isVisible,
  setVisible,
  updateApplicationStatus
}) => {

  return ( 
    <div className={isVisible ? 'application-modal' : 'hide'}>
      <div className='application-modal-content'>
        <div className='close'>
          <FontAwesomeIcon 
            size='lg'
            color='black' 
            icon={faClose} 
            onClick={() => setVisible(false)}
          /> 
        </div>
        <div className='applicant-name'>
          {`${application.studentDetails.firstName} ${application.studentDetails.lastName}`}
        </div>
        <div className='grade'>{application.grade.name}</div>
        <ApplicationStatus
          application={application}
          updateApplicationStatus={updateApplicationStatus}
        />
        <div className='section-header'>Student Details</div>
        <div className='key-value-pair'>
          <div className='key'>First Name:</div>
          <div className='value'>{application.studentDetails.firstName}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Last Name:</div>
          <div className='value'>{application.studentDetails.lastName}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Race:</div>
          <div className='value'>{isStringEmpty(application.studentDetails.otherRace) 
            ? application.studentDetails.race
            : application.studentDetails.otherRace}
          </div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Tel Home:</div>
          <div className='value'>{application.studentDetails.telHome}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Cell:</div>
          <div className='value'>{application.studentDetails.cell}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Email:</div>
          <div className='value'>{application.studentDetails.email}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Fax:</div>
          <div className='value'>{application.studentDetails.fax}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Gender:</div>
          <div className='value'>{application.studentDetails.gender}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>ID OR Birth Certificate Number:</div>
          <div className='value'>{application.studentDetails.idorBirthCertificateNumber}</div>
        </div>
        {application.studentDetails.disabilitiesOrMedicalConditionAffectStudies === 'Yes' && (
          <div className='key-value-pair'>
            <div className='key'>Medical Condition That May Affect Studies:</div>
            <div className='value'>{application.studentDetails.natureOfDisabilityOrMedicalCondition}</div>
          </div>
        )}
        <Address 
          description='Postal Address'
          address={application.studentDetails.postalAddress}
        />
        <Address 
          description='Residential Address'
          address={application.studentDetails.residentialAddress}
        />
        <div className='key-value-pair'>
          <div className='key'>ID OR BirthCertificate File:</div>
          <div 
            className='link'
            onClick={() => openInNewTab(application.studentDetails.idorBirthCertificateURL)}>
            File
          </div>
        </div>
        <div className='section-header'>Grade</div>
        <div className='key-value-pair'>
          <div className='key'>Grade Applying For:</div>
          <div className='value'>{application.grade.name}</div>
        </div>
        <div className='section-header'>Parent Or Guardian Details</div>
        <div className='key-value-pair'>
          <div className='key'>Relationship:</div>
          <div className='value'>{application.parentOrGuardianDetails.relationship}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>ID Number:</div>
          <div className='value'>{application.parentOrGuardianDetails.idNumber}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Tel Home:</div>
          <div className='value'>{application.parentOrGuardianDetails.telHome}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Tel Work:</div>
          <div className='value'>{application.parentOrGuardianDetails.telWork}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Cell:</div>
          <div className='value'>{application.parentOrGuardianDetails.cell}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Email:</div>
          <div className='value'>{application.parentOrGuardianDetails.email}</div>
        </div>
        <Address 
          description='Postal Address'
          address={application.parentOrGuardianDetails.postalAddress}
        />
        <Address 
          description='Residential Address'
          address={application.parentOrGuardianDetails.residentialAddress}
        />
        <div className='key-value-pair'>
          <div className='key'>ID OR Passport File:</div>
          <div 
            className='link'
            onClick={() => openInNewTab(application.parentOrGuardianDetails.idorPassportURL)}>
            File
          </div>
        </div>
        <div className='section-header'>Academic History</div>
        <div className='key-value-pair'>
          <div className='key'>Last school attended:</div>
          <div className='value'>{application.academicHistory.lastSchoolAttended}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Year:</div>
          <div className='value'>{application.academicHistory.year}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Country:</div>
          <div className='value'>{application.academicHistory.country}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Aggregate:</div>
          <div className='value'>{application.academicHistory.aggregate}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Latest Grade level complete:</div>
          <div className='value'>{application.academicHistory.latestGradeLevelCompleted}</div>
        </div>
        <div className='key-value-pair'>
          <div className='key'>Latest Report File:</div>
          <div 
            className='link'
            onClick={() => openInNewTab(application.academicHistory.latestSchoolReportURL)}>
            File
          </div>
        </div>
        <div className='section-header'>Declaration Details</div>
        <div className='key-value-pair'>
          <div className='key'>I, {application.declarationDetails.applicantDeclaration.fullName}</div>
          <div className='value-bold'>{application.declarationDetails.applicantDeclaration.idorBirthCertificateNumber}</div>
        </div>
        <div className='paragraph'>
          the undersigned, declare that all the particulars supplied by me in this form are true, complete and correct. 
          I accept that incorrect or misleading information could lead to the cancellation of this application.
        </div>
        <Signature
          description='Witness Signature Initials'
          signature={application.declarationDetails.applicantDeclaration.witnessSignature}
        />
        <Signature
          description='Applicant Signature Initials'
          signature={application.declarationDetails.applicantDeclaration.applicantSignature}
        />
        <Signature
          description='Parent Or Guardian Signature Initials'
          signature={application.declarationDetails.applicantDeclaration.parentOrGuardianSignature}
        />
        <div className='paragraph'>
          This section must be completed by the benefactor, i.e. the person who will be responsible for the payment of all tuition fees.
        </div>
        <div className='key-value-pair'>
          <div className='key'>I, {application.declarationDetails.benifactorDeclaration.fullName}</div>
          <div className='value-bold'>{application.declarationDetails.benifactorDeclaration.idorPassportNumber}</div>
        </div>
        <Signature
          description='Benifactor Signature Initials'
          signature={application.declarationDetails.benifactorDeclaration.signature}
        />
      </div>
    </div>
  );
}

export default ApplicationDetailsModal;