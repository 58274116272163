import './navbar.css'

import { useNavigate } from "react-router-dom";
import { FunctionComponent, useState, useEffect, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faBars, faCircleUser } from '@fortawesome/free-solid-svg-icons'

import { CurrentUserContext } from 'store';

type NavBarProps = {
  isHomePage: boolean;
  isRightNavBarVisible: boolean;
  setRightNavBarVisible: (value: boolean) => void;
}

const NavBar: FunctionComponent<NavBarProps> = ({
  isHomePage,
  isRightNavBarVisible,
  setRightNavBarVisible
}) => {
  const navigate = useNavigate();
  const [currentUser] = useContext(CurrentUserContext);
  const [scrollTop, setScrollTop] = useState(0);
  const [, setScrolling] = useState(false);
  const [activeTab, setActiveTab] = useState('Home')

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <nav className={scrollTop >= 50 ? 'second-navbar fixed-top' : 'second-navbar'}>
      <div className='left-section' onClick={() => navigate('/')}/>
      <div className='right-section' >
          {isHomePage && (
            <>
              <div 
                className={activeTab === 'Home' ? 'item active' : 'item'}
                onMouseOver={() => {
                  setActiveTab('Home');
                  document.getElementById("home")?.scrollIntoView({ block: 'start' });
                }}
              >Home</div>
              <div 
                className={activeTab === 'About' ? 'item active' : 'item'}
                onMouseOver={() => {
                  setActiveTab('About');
                  document.getElementById("about")?.scrollIntoView({ block: 'start' });
                }}
              >About</div>
              <div
                className={activeTab === 'Team' ? 'item active' : 'item'}
                onMouseOver={() => {
                  setActiveTab('Team');
                  document.getElementById("team")?.scrollIntoView({ block: 'center' });
                }}
              >Team</div>
              <div
                className={activeTab === 'News&Updates' ? 'item active' : 'item'}
                onMouseOver={() => {
                  setActiveTab('News&Updates');
                  document.getElementById("news-and-updates")?.scrollIntoView({ block: 'center' });
                }}
              >News & Updates</div>
              <div
                className={activeTab === 'Contact' ? 'item active' : 'item'}
                onMouseOver={() => {
                  setActiveTab('Contact');
                  document.getElementById("contact")?.scrollIntoView({ block: 'center' });
                }}
              >Contact</div>
            </>
          )}
          <div 
            className={'item'}
            onClick={() => navigate('/login') }
            onMouseOver={() => setActiveTab('Login')}
          >
            <FontAwesomeIcon 
              style={{ padding: '5px' }} 
              color={activeTab === 'Login' ? 'item active' : 'gray'} 
              icon={faCircleUser} size='1x' 
            />
            {!currentUser.firstName ? 'Login' : currentUser.firstName}
          </div>
      </div>
      <div className='bars-close-icons' onClick={() => setRightNavBarVisible(!isRightNavBarVisible)}>
          <FontAwesomeIcon className={!isRightNavBarVisible ? 'hide' : 'animate__animated animate__zoomIn'} color='black' icon={faClose} size='2x' />
          <FontAwesomeIcon className={isRightNavBarVisible ? 'hide' : 'animate__animated animate__zoomIn'} color='black' icon={faBars} size='2x' />
      </div>
    </nav>
  )
}

export default NavBar;