/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react';
import { openInNewTab } from "shared/utils/actions";
import SlideShow from './slide-show'

const SocialsSection: FunctionComponent = () => {
  return (
    <div id='news-and-updates' className='news-and-updates'>
      <div className="slide-show-section">
        <div className="title-section">
          <div className="title">News & Updates</div>
          <div className="title-arrow" />
        </div>
        <div className="sub-title">QUICK READS</div>
          <SlideShow />
        
      </div>
      <div className="quick-links">
        <div className="title-section">
          <div className="title">Quick Links</div>
          <div className="sub-title">ARTICLES</div>
        </div>
        <div 
          className="article"
          onClick={() => openInNewTab('https://ndabaonline.ukzn.ac.za/UkzndabaStory/Vol10-Issue3/UKZN%20Alumni%20and%20Students%20Develop%20App%20to%20Facilitate%20Tutoring%20for%20High%20School%20Learners?fbclid=IwAR1VJoH4LdWHc39DUWh2DjHiDDhpW4NjnsWseRA2YnKylY45ra8SmsCGBaM')}
        >
          <div className="logo-container">
            <div 
              className="logo"  
              style={{ backgroundImage: `url(${require("assets/images/home-page/icons/ukzn.png")})` }}
            />
          </div>
          <div className="date-and-title">
            <div className="date">FEB 17, 2022</div>
            <div className="title">UKZN Alumni and Students Develop App to Facilitate Tutoring for High School Learners</div>
          </div>
        </div>
        <div 
          className="article"
          onClick={() => openInNewTab('https://www.umkhanyisi.likhanyiletm.co.za/bahlose-ukwenza-ukufunda-kube-lula-ngefocus-mentorship-app/?fbclid=IwAR0mc2QFjeXfC_exHr0zCOd-D_4n8AmbbYQ8AZfoFE_EJgkSsHbOH0CceVw')}
        >
          <div className="logo-container">
            <div 
              className="logo"  
              style={{ backgroundImage: `url(${require("assets/images/home-page/icons/umkhanyisi.png")})` }}
            />
          </div>
          <div className="date-and-title">
            <div className="date">JULY 9, 2021</div>
            <div className="title">Bahlose ukwenza ukufunda kube lula ngeFocus Mentorship App</div>
          </div>
        </div>
        <div 
          className="article"
          onClick={() => openInNewTab('https://www.durban.gov.za/Resource_Centre/gazette/Documents/MetroEzasegagasini20_11_2020English.pdf?fbclid=IwAR2t6oSXUhzMYzTv_DwBR3NvDC9Jcf5-DD-JOMn-buKQkT0Z9FbrKnmG4jY')}
        >
          <div className="logo-container">
            <div 
              className="logo"  
              style={{ backgroundImage: `url(${require("assets/images/home-page/icons/ethekwini.png")})` }}
            />
          </div>
          <div className="date-and-title">
            <div className="date">NOV 11, 2020</div>
            <div className="title">Students urged to download Focus App</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialsSection;
