import './navbar.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default function Navbar() {

  return (
    <nav className='top-navbar'>
      <div className='left-section'>
        <div className='phone-number'>
          <FontAwesomeIcon color='white' icon={faPhone} size='sm' /> +27 65 843 0798
        </div>
        <div className='email'>
          <FontAwesomeIcon color='white' icon={faEnvelope} size='sm' /> info@focusmentorship.com
        </div>
      </div>
    </nav>
  )
}