/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react'

const About: FunctionComponent = () => {
  return (
    <div className='after-banner-section'>
        <div className="top-options">
            <div className="option" onClick={() => document.getElementById("about")?.scrollIntoView({ block: 'start' })}>
                <img className="image" src={require("../../../assets/images/home-page/icons/about-icon.png")} alt="About" title="About" />
                <div className="side-text">
                    <div className="title">About</div>
                    <div className="sub-title">Services</div>
                </div>
            </div>
            <div className="option" onClick={() => document.getElementById('team')?.scrollIntoView({ block: 'center' })}>
                <img className="image" src={require("../../../assets/images/home-page/icons/team-icon.png")} alt="Team" title="Team" />
                <div className="side-text">
                    <div className="title">Team</div>
                    <div className="sub-title">Members</div>
                </div>
            </div>
            <div className="option" onClick={() => document.getElementById('news-and-updates')?.scrollIntoView({ block: 'center' })}>
                <img className="image" src={require("../../../assets/images/home-page/icons/LAtest-News.png")} alt="" title="Articles" />
                <div className="side-text">
                    <div className="title">News</div>
                    <div className="sub-title">Articles</div>
                </div>
            </div>
            <div className="option" onClick={() => document.getElementById('contact')?.scrollIntoView({ block: 'center' })}>
                <img className="image" src={require("../../../assets/images/home-page/icons/contact-icon.png")} alt="Contact" title="Contact" />
                <div className="side-text">
                    <div className="title">Contact</div>
                    <div className="sub-title">Get in touch</div>
                </div>
            </div>
        </div>
        <div id='about'/>
        <div className="bottom-section">
            <div className="left-section" />
            <div id="legacy" className="right-section">
                <div className="right-section-box">
                    <div className="title">Tutors & Resources</div>
                    <div className="sub-title">The Focus Mentorship App primarily focuses on empowering the youth of South African by tackling the issues that affect the youth.</div>
                    <div className="paragraph">Lack of access to resources for students especially those that are in areas where resources are scarce.</div>
                    <div className="paragraph">Qualified unemployed youth to make some income through tutoring in their field of study.</div>
                    <div className="title">Resources sharing</div>
                    <div className="sub-title">Resources sharing on the app is made safe and easy. The app admins facilitate sharing of resources to ensure that the resources shared on the app are safe for other student to use.</div>
                    <div className="title">Tutoring</div>
                    <div className="sub-title">Tutors can register on the app and specify the subjects they are willing to tutor. For a tutor to have their profile actived as a tutor they need to have a at minimum 10 likes in their profile. Tutors are rated in the app as well as students after every tutorial session to ensure for best quality.</div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default About;
