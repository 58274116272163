import './index.css'

import { FunctionComponent, useContext } from "react";

import { Application } from 'api/applications/types';

import { CurrentUserContext } from 'store';
import { RegistrationComponentsContext } from 'registration/store';
import FormControlSelect from "registration/shared/components/dimensional-form-control-select/1D-form-control-select-pair-no-label";

type ApplicationsProps = {
  application: Application;
  updateApplicationStatus: (application: Application) => void;
}

const ApplicationDetailsModal: FunctionComponent<ApplicationsProps> = ({
  application,
  updateApplicationStatus
}) => {
  const [currentUser] = useContext(CurrentUserContext);
  const [applicationComponents] = useContext(RegistrationComponentsContext);

  return ( 
    <div className={'application-status'}>
      <div className='title'>Application Status:</div>
      {currentUser.isAHSAdmin
        ? <FormControlSelect
            field='applicationStatus'
            fieldId='id'
            fieldName='name'
            index={false}
            options={applicationComponents.applicationStatuses}
            applicationDetails={application}
            setApplicationDetails={updateApplicationStatus}
          />
        : <div className='status'>{application.applicationStatus.name}</div>
      }
    </div>
  );
}

export default ApplicationDetailsModal;