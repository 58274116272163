import { useState, createContext } from 'react';
import { applicationDetailsInitialState } from './data';

export const ApplicationDetailsContext = createContext<any>({})
export const RegistrationModalVisibilityContext = createContext<any>(false)
export const RegistrationComponentsContext = createContext<any>(applicationDetailsInitialState)

export default function RegistrationStore(props: any) {
	const [registrationComponents, setRegistrationComponents] = useState({
		grades: [{ name: 'No grades available to select' }]
	})
	const [isRegistrationModalVisible, setRegistrationModalVisible] = useState(false);
	const [applicationDetails, setApplicatinDetails] = useState(applicationDetailsInitialState);

	return (
		<RegistrationModalVisibilityContext.Provider value={[isRegistrationModalVisible, setRegistrationModalVisible]}>
			<ApplicationDetailsContext.Provider value={[applicationDetails, setApplicatinDetails]}>
				<RegistrationComponentsContext.Provider value={[registrationComponents, setRegistrationComponents]}>
					{props.children}
				</RegistrationComponentsContext.Provider>
			</ApplicationDetailsContext.Provider>
		</RegistrationModalVisibilityContext.Provider>
	);
};