export const applicationDetailsInitialState = {
    studentDetails: {
        firstName: "",
        lastName: "",
        iDORBirthCertificateNumber: "",
        residentialAddress: {
          line1: "",
          line2: "",
          line3: "",
          postalCode: ""
        },
        postalAddress: {
          line1: "",
          line2: "",
          line3: "",
          postalCode: ""
        },
        telHome: "",
        cell: "",
        fax: "",
        email: "",
        title: "",
        race: "",
        otherRace: "",
        gender: "",
        disabilitiesOrMedicalConditionAffectStudies: "",
        natureOfDisabilityOrMedicalCondition: "",
        applicantIDORBirthCertificateFile: null,
        applicantIDORBirthCertificateFileName: ''
    },
    grade: {
      id: 0,
      name: ''
    },
    parentOrGuardianDetails: {
        relationship: "",
        idNumber: "",
        telWork: "",
        telHome: "",
        cell: "",
        email: "",
        residentialAddress: {
          line1: "",
          line2: "",
          line3: "",
          postalCode: ""
        },
        postalAddress: {
          line1: "",
          line2: "",
          line3: "",
          postalCode: ""
        },
        parentOrGuardianIDORPassportFile: null,
        parentOrGuardianIDORPassportFileName: ''
    },
    academicHistory: {
        lastSchoolAttended: "",
        year: "",
        country: "",
        aggregate: "",
        latestGradeLevelComplete: "",
        applicantLatestSchoolReportFile: null,
        applicantLatestSchoolReportFileName: ''
    },
    declarationDetails: {
        applicantDeclaration: {
          fullName: "",
          idOrBirthCertificateNumber: "",
          applicantSignature: {
            initials: "",
            date: new Date().toDateString()
          },
          witnessSignature: {
            initials: "",
            date: new Date().toDateString()
          },
          parentOrGuardianSignature: {
            initials: "",
            date: new Date().toDateString()
          }
        },
        benifactorDeclaration: {
          fullName: "",
          iDORPassportNumber: "",
          signature: {
            initials: "",
            date: new Date().toDateString()
          }
        }
    }
}