import './index.css'

import { FunctionComponent } from "react";
import moment from 'moment';

import { Signature as SignatureType } from 'api/applications/types';

type SignatureProps = {
  description: string;
  signature: SignatureType;
}

const Signature: FunctionComponent<SignatureProps> = ({
  description,
  signature
}) => {
  return ( 
    <div className='signature'>
        <div className='description'>{description}</div>
        <div className='details'>
          <div className='initials'>{signature.initials}</div>
          <div className='date'>{moment(signature.createdAt).format('DD MMM YYYY')}</div>
        </div>
    </div>
  );
}

export default Signature;