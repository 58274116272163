/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent, useEffect, useRef } from 'react'
import { useCountUp } from 'react-countup';
import useOnScreen from "shared/utils/useOnScreen";
import { openInNewTab } from "shared/utils/actions";


const StatsSection: FunctionComponent = () => {
  const ref: any = useRef();
  const isVisible = useOnScreen(ref);
  const instagramFollowersCountUpRef = useRef(null);
  const facebookLikesCountUpRef = useRef(null);
  const facebookFollowersCountUpRef = useRef(null);
  const playStoreDownloadsCountUpRef = useRef(null);
  
  const { start: facebookLikesCountUpStart } = useCountUp({ ref: facebookLikesCountUpRef, end: 1483  });
  const { start: facebookFollowersCountUpStart } = useCountUp({ ref: facebookFollowersCountUpRef, end: 1529 });
  const { start: instagramFollowersCountStart } = useCountUp({ ref: instagramFollowersCountUpRef, end: 634 });
  const { start: playStoreDownloadsCountStart } = useCountUp({ ref: playStoreDownloadsCountUpRef, end: 1000, suffix: '+' });

  useEffect(() => {
    if(isVisible) {
      facebookLikesCountUpStart()
      playStoreDownloadsCountStart()
      instagramFollowersCountStart()
      facebookFollowersCountUpStart()
  // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return (
    <div ref={ref} id='statistics' className='stats-section'>
        <div className="record">
            <div className="value" ref={facebookLikesCountUpRef} />
            <div className="description">PEOPLE THAT LIKE THE PAGE ON FACEBOOK</div>
            <div 
              className="title"
              onClick={() => openInNewTab("https://www.facebook.com/FOCUS-Mentorship-186104458799069")}
            >FACEBOOK LIKES</div>
        </div>
        <div className="record">
            <div className="value" ref={facebookFollowersCountUpRef} />
            <div className="description">PEOPLE THAT FOLLOW THE PAGE ON FACEBOOK</div>
            <div 
              className="title"
              onClick={() => openInNewTab("https://www.facebook.com/FOCUS-Mentorship-186104458799069")}
            >FACEBOOK FOLLOWERS</div>
        </div>
        <div className="record">
            <div className="value" ref={playStoreDownloadsCountUpRef} />
            <div className="description">GOOGLE PLAY STORE DOWNLOADS</div>
            <div 
              className="title"
              onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=com.targetonline.focus")}
            >DOWNLOADS 1K+</div>
        </div>
        <div className="record">
            <div className="value" ref={instagramFollowersCountUpRef} />
            <div className="description">PEOPLE THAT FOLLOW THE PAGE ON INSTAGRAM</div>
            <div 
              className="title"
              onClick={() => openInNewTab("https://www.instagram.com/focusmentorship/")}
            >INSTAGRAM FOLLOWERS</div>
        </div>
    </div>
  );
};

export default StatsSection;
