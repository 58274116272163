/* eslint-disable react/prop-types */
import { useState } from "react";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useStyles from "registration/shared/utils/use-styles";

const FormControlSelect = ({
  options,
  index,
  field,
  fieldId,
  fieldName,
  applicationDetails,
  setApplicationDetails
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(applicationDetails[field][fieldName]);
  return (
    <FormControl className={classes.formControl}>
      <Select
        native
        fullWidth
        labelWidth={5}
        value={value}
        onChange={event => {
          setValue(event.target.value)
          setApplicationDetails({
            ...applicationDetails,
            [field]: {
              ...applicationDetails[field],
              [fieldId]: options.find(o => o.name === event.target.value).id,
              [fieldName]: event.target.value
            }
          });
        }}
      >
        {options.map((prop, key) => (
          <option value={index ? key : prop.name} prop={prop} key={key}>
            {prop.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}
export default FormControlSelect;
