import './index.css'

import { FunctionComponent, useState } from "react";
import moment from 'moment';

import { Application } from 'api/applications/types';
import View from 'applications/view'

type ApplicationProps = {
    application: Application;
    updateApplicationStatus: (application: Application) => void;
}
  
const ApplicationCard: FunctionComponent<ApplicationProps> = ({
    application,
    updateApplicationStatus
  }) => {
    const [isModalVisble, setModalVisible] = useState(false); 
    return ( 
        <>
            <div className='card' onClick={() => setModalVisible(true)}>
                <div className='header-bottom-container'>
                    <div className='header-section'>
                    <div className='avatar' style={{
                        backgroundImage: `url(${application.applicant.avatarURL})`
                    }} />
                    <div className='subject-name'>{application.grade.name}</div>
                    </div>
                    <div className='bottom-section'>
                        <div className='aplication-status'>
                            {application.applicationStatus.name}
                        </div>
                        <div className='applicant-name'>
                            {`${application.studentDetails.firstName} ${application.studentDetails.lastName}`}
                        </div>
                        <div className='application-time-stamp'>
                            {moment(application.createdAt).format('DD MMM YYYY')}
                        </div>
                    </div>
                </div>
            </div>
            <View 
                application={application}
                isVisible={isModalVisble}
                setVisible={setModalVisible}
                updateApplicationStatus={updateApplicationStatus}
            />
        </>
    );
}

export default ApplicationCard;