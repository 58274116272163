import { FunctionComponent, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import * as applicationsApi from "api/applications";
import useApi from 'shared/utils/react_use_api';
import NotifyFailure from 'shared/utils/notify-failure';
import Applications from './list';
import { Application, ApplicationStatusUpdateResponse } from "api/applications/types";
import { toast } from "react-toastify";
import { isEmpty } from "shared/utils/string";
import { CurrentUserContext } from "store";

const ApplicationsController: FunctionComponent = () => {
    const navigate = useNavigate();
    const [currentUser] = useContext(CurrentUserContext);

    useEffect(() => {
        if(isEmpty(currentUser)) navigate('/login');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getApplications = useApi({
        action: () => applicationsApi.get(),
        initialValue: [],
        onError: (error: any) => NotifyFailure(error.response, error.message)
    }, [])

    const updateApplicationStatus = useApi({
        action: (application: Application) => applicationsApi.updateApplicationStatus(application),
        initialValue: [],
        defer: true,
        onSuccess: (response: ApplicationStatusUpdateResponse) => {
            getApplications.setData(
                getApplications.data.map((a: Application) => a.id === response.applicationId ? {
                    ...a,
                    applicationStatus: response.applicationStatus
                } : a)
            )
            toast.success('Application Status Updated Successfully')
        },
        onError: (error: any) => NotifyFailure(error.response, error.message)
    }, [])

    let inProgress = getApplications.inProgress || updateApplicationStatus.inProgress;

    return (
        <Applications
            inProgress={inProgress}
            applications={getApplications.data}
            updateApplicationStatus={(application: any) => updateApplicationStatus.execute(application)}
        />
    )
}

export default ApplicationsController;