import "@patternfly/react-core/dist/styles/base.css";
import "react-toastify/dist/ReactToastify.css";
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Home from 'home';
import Store from 'store'
import RegistrationStore from 'registration/store'
import Applications from 'applications';
import Login from 'authentication/login';
import Signup from 'authentication/signup';
import reportWebVitals from 'reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
    <Store>
      <RegistrationStore>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/applications" element={<Applications />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
      </RegistrationStore>
    </Store>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
