/* eslint-disable react/prop-types */
import "./index.css";

import { FunctionComponent } from 'react'
import { openInNewTab } from "shared/utils/actions";

const MainBanner: FunctionComponent = () => {
  return (
    <div className='main-banner'>
      <div className="banner-overlay-content">
        <div className="digital-stores-btns">
          <div className="btn play-store" onClick={() => openInNewTab("https://play.google.com/store/apps/details?id=com.targetonline.focus")}/>
          <div className="btn app-store" onClick={() => openInNewTab("https://apps.apple.com/us/app/focus-mentorship/id1507260739")}/>
          <div className="btn app-gallery" onClick={() => openInNewTab("https://appgallery.huawei.com/app/C104343307")}/>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
