import { FunctionComponent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { CurrentUserContext } from "store";
import { userInitialState } from '../contants';
import * as authApi from "api/authentication";
import { User } from 'api/authentication/types';
import useApi from 'shared/utils/react_use_api';
import NotifyFailure from 'shared/utils/notify-failure';
import { sendExpoPushNotifications } from "shared/utils/expo-notifications";
import SignUp from "./component";

const AuthenticationController: FunctionComponent = () => {
    const navigate = useNavigate();
    const [user] = useState<User>(userInitialState)
    const [, setCurrentUser] = useContext(CurrentUserContext);

    /*
    useEffect(() => {
        var focusAppUser = localStorage.getItem('focusAppUser');
        if(focusAppUser) {
            toast.info('Auto login in progress please wait...')

            var user = JSON.parse(focusAppUser)
            setUser({ ...user, phoneNumber: trim(user.phoneNumber) });
            postLogin.execute(user)
        }
    }, [])
    */
   
    const postLogin = useApi({
        action: (user: User) => authApi.postLogin(user),
        defer: true,
        onSuccess: (user: User) => {
            localStorage.setItem('focusAppUser', JSON.stringify({
                ...user,
                tutorAttribute: null
            }));

            setCurrentUser(user);

            navigate('/');
        },
        onError: async (e: any) => {
            if(e.response && e.response.data && e.response.data.errorMessages[0].includes(user.phoneNumber)) {
                localStorage.removeItem('focusAppUser');
            }

            return NotifyFailure(e.response, e.message)
        }
    }, [])

    const postSignup = useApi({
        action: (user: User) => authApi.postSignup(user),
        defer: true,
        onSuccess: async (sinupResponse: { adminsExpoPushTokens: string[], user: User }) => {
            sendExpoPushNotifications(
                sinupResponse.adminsExpoPushTokens, 
                '🤩 New user @Albini High School 🤩',
                `${sinupResponse.user.firstName} ${sinupResponse.user.lastName}`
            )

            localStorage.setItem('focusAppUser', JSON.stringify({
                ...sinupResponse.user,
                tutorAttribute: null
            }));

            setCurrentUser(sinupResponse.user); 

            navigate('/');
        },
        onError: (error: any) => NotifyFailure(error.response, error.message)
    }, [])

    return (
        <SignUp
            user={user}
            inProgress={postLogin.inProgress}
            onSignup={(user: any) => postSignup.execute(user)}
        />
    )
}

export default AuthenticationController;